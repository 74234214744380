@tailwind components;

@layer components {
  .icon {
    &.sm {
      @apply h-3.5 w-3.5;
    }

    &.md {
      @apply h-5 w-5;
    }

    &.lg {
      @apply h-7 w-auto max-w-[1.75rem];
    }

    &.loading {
      @apply animate-spin !fill-transparent text-white;
    }
  }
}
