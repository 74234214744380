@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply min-h-full w-full;
}

#root {
  @apply scroll-smooth;
}

.simplebar-content {
  @apply h-full;

  .simplebar-content-wrapper {
    @apply outline-none;
  }
}

@layer utilities {
  .bullet-point-before {
    @apply before:absolute before:top-1/2 before:block before:h-2 before:w-2 before:-translate-x-8 before:-translate-y-1/2 before:rounded-full;
  }
}

.swiper {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    @apply text-3xl text-secondary;
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
